/**
 * IMPORTS
 */

import { extendTheme } from '@chakra-ui/react';

/**
 * CORE
 */

// @see https://smart-swatch.netlify.app/ (get JS object)
const colors = {
  brand: {
    50: '#dbf2ff',
    100: '#b4dbfc',
    200: '#89c7f3',
    300: '#5cb5ed',
    400: '#32a5e7',
    500: '#1892cd',
    600: '#0a67a1',
    700: '#004274',
    800: '#002348',
    900: '#000a1e',
  },
};

const fonts = {
  body: '"Open Sans",Arial,sans-serif',
  heading: 'Oswald,Helvetica,Arial,sans-serif',
};

const Button = {
  baseStyle: {
    borderRadius: 'full',
  },
};

const Heading = {
  baseStyle: {
    color: 'brand.500',
    textTransform: 'uppercase',
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button,
    Heading,
  },
});

export default theme;
