/**
 * IMPORTS
 */

import React, { useState, useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Container, Heading, Image, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { use100vh } from 'react-div-100vh';

import * as actions from '../../actions';
import { MSState } from '../../reducers';
import { getExpectedCount, getPhotoUri, getValidatedCount } from '../../selectors';
import { useEventTracker } from '../../hooks';

import CaptureStep from './CaptureStep';
import PreviewStep from './PreviewStep';
import GuideStep from './GuideStep';
import Header from '../common/Header';
import Footer from '../common/Footer';

import { getCaptureImage } from '../../utils/images';
import { useTranslation } from '../../utils/translate';
import CaptureContext from './CaptureContext';
import FullHeightFlex from '../common/FullHeightFlex';
import MinHSpacer from '../common/MinHSpacer';
import iaapaLogo from '../../assets/iaapa-logo.svg';

/**
 * CORE
 */

const connector = connect(
  (state: MSState) => ({
    photoUri: getPhotoUri(state),
    expectedCount: getExpectedCount(state),
    validatedCount: getValidatedCount(state),
  }),
  {
    showPhoto: actions.usePhoto, // note: we cannot name it usePhoto, or it's considered a react hook...
    validatePhoto: actions.validatePhoto,
    resetPhoto: actions.resetPhoto,
    reset: actions.reset,
  },
);

type CaptureScreenProps = ConnectedProps<typeof connector> & {};

function CaptureScreen({
  photoUri,
  expectedCount,
  validatedCount,
  showPhoto,
  validatePhoto,
  resetPhoto,
  reset,
}: CaptureScreenProps) {
  const [readInstructions, setReadInstructions] = useState(false);
  const [photoTaken, setPhotoTaken] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const height = use100vh();
  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent({ eventType: 'STEP_START', step: 'CAPTURE' })
  }, [trackEvent]);

  // ugly fix
  useEffect(
    () => {
      const elems = document.getElementsByClassName('chakra-modal__content-container') as HTMLCollectionOf<HTMLDivElement>;
      if (elems[0]) {
        elems[0].style.height = `${height}px` || '100vh';
      }
    },
    [height, isOpen],
  );

  useEffect(
    () => {
      setReadInstructions(false);
      setPhotoTaken(false);
    },
    [validatedCount],
  );

  const handleValidateInstructions = useCallback(() => setReadInstructions(true), []);

  const handlePhotoTaken = useCallback(
    (photoUri: string) => {
      showPhoto(photoUri);
      setPhotoTaken(true);
    },
    [showPhoto],
  );

  const handleModalExit = useCallback(
    () => {
      onClose();
      reset();
    },
    [reset, onClose],
  );

  let step: React.ReactNode;
  const captureIndex = validatedCount + 1;
  if (!readInstructions) {
    step = (
      <GuideStep
        image={getCaptureImage(captureIndex)}
        onValidateInstructions={handleValidateInstructions}
      />
    );
  } else if (!photoTaken || !photoUri) {
    step = (
      <CaptureStep
        onTakePhoto={handlePhotoTaken}
      />
    );
  } else {
    step = (
      <PreviewStep
        photoUri={photoUri || ''}
        onValidate={validatePhoto}
        onRetry={resetPhoto}
      />
    );
  }

  return (
    <>
      <FullHeightFlex direction="column">
        <Header />

        <MinHSpacer />

        <Container>
          <Stack spacing="1rem">
            <Heading textAlign="center">
              {t('home.title')}
            </Heading>

            <Text textAlign="center">
              {t('home.introduction')}
            </Text>

            <Button
              size="lg"
              colorScheme="brand"
              onClick={onOpen}
              isFullWidth
            >
              {t('home.start-button')}
            </Button>
          </Stack>
        </Container>

        <MinHSpacer />

        <Image
          alt="iaapa-connections"
          src={iaapaLogo}
          maxWidth="150px"
          margin="auto"
          marginBottom="1rem"
        />
      
        <Footer />
      </FullHeightFlex>

      <Modal
        isOpen={isOpen}
        onClose={handleModalExit}
        // closeOnOverlayClick={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent
          margin="1rem"
          overflow="hidden"
        >
          <ModalBody
            paddingX={0}
            paddingTop={0}
            paddingBottom="1rem"
          >
            <CaptureContext.Provider value={{ captureIndex, expectedCount }}>
              {step}
            </CaptureContext.Provider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default connector(CaptureScreen);
