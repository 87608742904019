/**
 * IMPORTS
 */

import React from 'react';
import { Center, CircularProgress } from '@chakra-ui/react';

/**
 * CORE
 */

function FallbackImage() {
  return (
    <Center h="100%">
      <CircularProgress isIndeterminate />
    </Center>
  );
}

export default FallbackImage;
