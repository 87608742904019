/**
 * IMPORTS
 */

import React from 'react';
import { Flex, Text, Stack } from '@chakra-ui/react';
import Lottie from 'react-lottie';

import FullHeightFlex from './FullHeightFlex';
import MinHSpacer from './MinHSpacer';

import loaderAnimation from '../../assets/loader.json';

/**
 * CORE
 */

interface SpinningWheelProps {
  text: string;
  centerLabel?: string;
}

function SpinningWheel({ text, centerLabel }: SpinningWheelProps) {
  return (
    <FullHeightFlex direction="column">
      <MinHSpacer />

      <Stack align="center">
        <Flex position="relative" justifyContent="center" alignItems="center">
          <Lottie
            width={75}
            height={75}
            options={{
              loop: true,
              autoplay: true,
              animationData: loaderAnimation,
            }}
          />
          {centerLabel && (
            <Text position="absolute" fontSize="xs">
              {centerLabel}
            </Text>
          )}
        </Flex>

        <Text>
          {text}
        </Text>
      </Stack>

      <MinHSpacer />
    </FullHeightFlex>
  );
}

export default SpinningWheel;
